import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";


export const CommonApi = {

    getProjects: async (query, cancel = false) => {
        const response = await api.request({
            url: "/project/",
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('auth')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getPackageList: async (query, cancel = false) => {
        const response = await api.request({
            url: "/packages/",
            method: "GET",
            // headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        if (response.data?.code == 200) {
            response.data.success = true
        }

        return response.data;
    },

    getNearbyEvents: async (query, cancel = false) => {
        const response = await api.request({
            url: "/near-by-events/",
            method: "GET",
            // headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        if (response.data?.code == 200) {
            response.data.success = true
        }

        return response.data;
    },

    getNearbyEventsById: async (id, cancel = false) => {
        const response = await api.request({
            url: `/near-by-events/${id}/`,
            method: "GET",
            // headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        if (response.data?.code == 200) {
            response.data.success = true
        }

        return response.data;
    },

    getMasterData: async (cancel = false) => {
        const response = await api.request({
            url: "/master-data/",
            method: "GET",
            // headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        if (response.data?.code == 200) {
            response.data.success = true
        }

        return response.data;
    }



}

const cancelApiObject = defineCancelApiObject(CommonApi);
