import React, { useEffect } from "react";
import { AdminAPI } from "../../Apis/adminAPI";
import { Box, Text } from "@chakra-ui/react";
import { Table } from "antd";
import { render } from "@testing-library/react";
import { CommonApi } from "../../Apis/commonApi";
import Loader from "../../Components/Common/Loader";

const Projects = () => {
  const [projects, setProjects] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      render: (text: any, record: any) => (
        <Text>{record.company.name}</Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <Text>{record.status.name}</Text>
      ),
    },

    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      render: (text: any, record: any) => (
        <Text>{record.site.name}</Text>
      ),
    },
  ];

  useEffect(() => {
    const getProjects = async () => {
      try {
        setLoading(true);
        const res = await CommonApi.getProjects();

        console.log(res);
        if (res.success) {
          setProjects(res.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getProjects();

    return () => {};
  }, []);

  return (
    <>
    {
      loading && <Loader />
    }
      <Box w={"100%"} display={"flex"}>
        <Text
          fontSize="2xl"
          textAlign={"center"}
          fontWeight="semibold"
          mb="30px"
        >
          Project List
        </Text>
      </Box>
      <Box
        overflow={"scroll"}
        mt={"10px"}
        pt={"20px"}
        borderTop={"2px"}
        borderColor={"gray.300"}
      >
        <Table
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "scroll",
            background: "#fff",
            width: "100%",
          }}
          columns={columns}
          dataSource={projects}
          pagination={false}
        />
      </Box>
    </>
  );
};

export default Projects;
