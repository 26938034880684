import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import Loader from "../Components/Common/Loader";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthAPI } from "../Apis/authAPI";
import { useNavigate } from "react-router-dom";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import { MdHelpOutline, MdLock, MdMail } from "react-icons/md";
import { companyLogo } from "../Assets";
import Input from "../Components/Common/Input";
import { AdminAPI } from "../Apis/adminAPI";
import { Spin } from "antd";
import InfoModal from "../Components/Common/InfoModal";

enum ScreenStateEnum {
  REGISTER = "register",
  SUCCESS = "success",
}

const Register = () => {
  const [inputFields, setInputFields] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    address: "",
    connectwiseClientId: "",
    connectwiseAuthKey: "",
  });

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const [screenState, setScreenState] = useState<ScreenStateEnum>(
    ScreenStateEnum.REGISTER
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (
      Object.values(inputFields).some((value) => value === "") ||
      Object.values(inputFields).some((value) => value === "")
    ) {
      error("Please fill all the fields");
      return;
    }

    try {
      setLoading(true);
      const res: any = await AuthAPI.register(inputFields);

      if (res.success) {
        success("Register success");
        localStorage.setItem("session", res.data);
        localStorage.setItem("auth", res.data.token);
        setScreenState(ScreenStateEnum.SUCCESS);
      }
    } catch (err: any) {
      error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitRef = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleInputChange = (fieldName: string, value: string) => {
    setInputFields({ ...inputFields, [fieldName]: value });
  };

  const [isOpen, setIsOpen] = useState<string | null>(null);

  const inputFieldsStatic: any = {
    companyName: {
      value: "",
      label: "Company Name",
      placeholder: "Enter Company Name",
      gridSize: 2,
    },
    email: {
      value: "",
      label: "Email",
      placeholder: "Enter Email",
    },
    phone: {
      value: "",
      label: "Phone",
      placeholder: "Enter Phone",
    },
    address: {
      value: "",
      label: "Address",
      placeholder: "Enter Address",
      gridSize: 2,
    },
    connectwiseClientId: {
      value: "",
      label: (
        <Text display={"flex"} alignItems={"center"} gap={"5px"}>
          Connectwise Cient Id{" "}
          <MdHelpOutline
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen("clientId");
            }}
          />
        </Text>
      ),
      placeholder: "Enter Connectwise Client Id",
      gridSize: 2,
    },

    connectwiseAuthKey: {
      value: "",
      label: (
        <Text display={"flex"} alignItems={"center"} gap={"5px"}>
          Connectwise Auth Key{" "}
          <MdHelpOutline
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen("authKey");
            }}
          />
        </Text>
      ),
      placeholder: "Enter Connectwise Auth Key",
      gridSize: 2,
    },
    firstName: {
      value: "",
      label: "First Name",
      placeholder: "Enter First Name",
    },
    lastName: {
      value: "",
      label: "Last Name",
      placeholder: "Enter Last Name",
    },
  };

  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <InfoModal
        isOpen={isOpen !== null}
        onClose={() => {
          setIsOpen(null);
        }}
        type={isOpen as any}
      />
      {loading ? <Loader /> : null}
      {screenState === ScreenStateEnum.SUCCESS ? (
        <MigrateUsers />
      ) : (
        <Box width={"600px"} shadow={"md"} p={10} border={"1px solid #e5e5e5"}>
          <Box>
            <Image
              src={companyLogo}
              alt="logo"
              margin={"auto"}
              height={"50px"}
              objectFit={"contain"}
              background={"#000"}
              mb={"15px"}
            />
            <Text
              fontWeight={"500"}
              color={"primary.main"}
              fontSize={"3xl"}
              textAlign={"center"}
            >
              Register
            </Text>
          </Box>
          <Box mt={"20px"}>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              {Object.entries(inputFields).map(([fieldName, value]) => (
                <GridItem
                  key={fieldName}
                  colSpan={inputFieldsStatic[fieldName].gridSize ?? 1}
                >
                  <Input
                    type="text"
                    value={value}
                    onChange={(value) => handleInputChange(fieldName, value)}
                    label={inputFieldsStatic[fieldName].label}
                    placeholder={inputFieldsStatic[fieldName].placeholder}
                  />
                </GridItem>
              ))}
            </Grid>

            <Box
              mt={"10px"}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              Already have an account?{"  "}
              <Link href="/forgot" color={"blue.500"} ml={2}>
                Sign In
              </Link>
            </Box>

            <Button
              ref={submitRef}
              onClick={handleSubmit}
              mt={"30"}
              width={"full"}
              h={"45px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"8px"}
              display={"flex"}
              background={"primary.main"}
              color={"white"}
              _hover={{ background: "primary.main" }}
            >
              Create Account
            </Button>
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export default Register;

const MigrateUsers = () => {
  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const navigate = useNavigate();

  const handleMigrateUsers = async () => {
    try {
      setLoading(true);
      const res: any = await AdminAPI.migrateUsers();

      console.log(res);

      if (res.success) {
        success("Users migrated successfully");
        navigate("/company/admin");
        // navigate("/login");
      }
    } catch (err: any) {
      error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width={"600px"}
      height={"400px"}
      shadow={"md"}
      p={10}
      display={"flex"}
      flexDirection={"column"}
      border={"1px solid #e5e5e5"}
    >
      {loading ? (
        <Text
          mt={"auto"}
          fontWeight={"500"}
          color={"gray.800"}
          fontSize={"lg"}
          textAlign={"center"}
        >
          Syncing Data, please wait...{" "}
          <Spin
            style={{
              marginLeft: "10px",
            }}
          />
        </Text>
      ) : (
        <>
          <Text
            fontWeight={"500"}
            color={"primary.main"}
            fontSize={"3xl"}
            textAlign={"center"}
          >
            Account Registered Successfully
          </Text>
          <Text
            fontWeight={"500"}
            color={"gray.500"}
            fontSize={"sm"}
            textAlign={"center"}
          >
            Hurray 🎉🎉🎉, your account has been registered successfully
            <br />
            Now we need to sync data from connecwise to timpunch app, click on
            the button below to proceed
          </Text>
        </>
      )}

      <Button
        onClick={() => handleMigrateUsers()}
        mt={"auto"}
        isLoading={loading}
        width={"full"}
        h={"45px"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={"8px"}
        display={"flex"}
        background={"primary.main"}
        color={"white"}
        _hover={{ background: "primary.main" }}
      >
        Sync Data
      </Button>
    </Box>
  );
};
