import React from "react";
import { Box, Text, chakra } from "@chakra-ui/react";

const NoData = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyItems={"center"}
      height={"100vh"}
      width={"100vw"}
    >
      <Text
      textAlign={"center"}
      width={"100%"}
        fontSize={"4xl"}
        fontWeight={"semibold"}
        mb={"20px"}
      >
        <chakra.span color={"red"}>*/</chakra.span> No Found
      </Text>
    </Box>
  );
};

export default NoData;
