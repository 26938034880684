import React, { useEffect, useState } from "react";
import { Menu } from "antd";
// Icons
import { AiOutlineHome, AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import {
  MdLogout,
  MdOutlineDashboard,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { GoProject } from "react-icons/go";
import { BsCheck2Square, BsPerson } from "react-icons/bs";
import { GiOrganigram } from "react-icons/gi";
import { LiaPersonBoothSolid } from "react-icons/lia";

import { useLocation, useNavigate, Outlet } from "react-router-dom";
import Topbar from "../../Partials/Admin/Topbar";
import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { companyLogo } from "../../../Assets";
import { FaTicketSimple } from "react-icons/fa6";
import { LuSheet } from "react-icons/lu";

type Props = {
  children?: React.ReactNode;
};

const CompanyAdminLayout = (props: Props) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const [collapsed, setCollapsed] = useState(false);

  const onClickMenuItem = (e: any) => {
    setSelectedKey(e.key);
    // navigate(keyRouteMap[e.key])
  };

  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      icon: <BsPerson size={20} className="text-[#FFFFFF]" />,
      label: "Users",
      path: "/company/admin",
    },
    {
      key: "2",
      icon: <AiOutlineHome size={20} className="text-[#FFFFFF]" />,
      label: "Projects",
      path: "/company/projects",
    },
    {
      key: "3",
      icon: <FaTicketSimple size={20} className="text-[#FFFFFF]" />,
      label: "Tickets",
      path: "/company/tickets",
    },
    {
      key: "4",
      icon: <LuSheet size={20} className="text-[#FFFFFF]" />,
      label: "Timesheets",
      path: "/company/timesheet",
    },
  ];

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setAuth(true);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Flex flexDirection={"row"} w={"100%"} maxW={"100vw"} overflowX={"hidden"}>
      <Box>
        <Menu
          onClick={onClickMenuItem}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          style={{
            width: !collapsed ? "180px" : "60px",
            backgroundColor: "#F1F1F1",
            minHeight: "100vh",
            maxHeight: "100vh",
            border: 0,
            flexDirection: "column",
            display: "flex",
            paddingBottom: "40px",
          }}
          selectedKeys={[selectedKey]}
          inlineCollapsed={collapsed}
        >
          <div className="flex h-[60px] items-center">
            {collapsed ? null : (
              <Image src={companyLogo} background={"#000"} p={"10px"} />
            )}
          </div>

          {/* <VStack spacing={2}> */}
          {items.map((item) => (
            <Menu.Item
              onClick={() => {
                navigate(item.path);
              }}
              style={{
                backgroundColor: "transparent",
                color: "black",
                margin: "15px 0",
                border:
                  location.pathname == item.path ? "1px solid #050A30" : "none",
              }}
              key={item.key}
              icon={item.icon}
            >
              <Text fontSize={"md"} fontWeight={500}>
                {item.label}
              </Text>
            </Menu.Item>
          ))}
          {/* </VStack> */}
          <Box mt={"auto"}>
            <Button
              onClick={() => {
                localStorage.clear();
                window.location.href = "/login";
              }}
            >
              <MdLogout size={20} />
            </Button>
          </Box>
        </Menu>
      </Box>

      <Box
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        boxSizing="border-box"
        maxW={"100%"}
        maxH={"100vh"}
      >
        <Topbar setCollapsed={setCollapsed} />
        <Box flex={1} overflowY={"auto"} padding={"10px"}>
          {auth && <Outlet />}
        </Box>
      </Box>
    </Flex>
  );
};

export default CompanyAdminLayout;
