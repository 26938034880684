import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input as ChakraInput,
} from "@chakra-ui/react";
import React from "react";

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  type: string;
  placeholder: string;
  leftElement?: any;
  rightElement?: any;
}

const Input = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  leftElement,
  rightElement,
  ...rest
}: InputProps) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {leftElement && (
          <InputLeftElement h={"50px"} pointerEvents="none">
            {leftElement}
          </InputLeftElement>
        )}
        <ChakraInput
          h={"50px"}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
      </InputGroup>
    </FormControl>
  );
};

export default Input;
