import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";


export const AuthAPI = {

    postLogin: async (login, cancel = false) => {
        const response = await api.request({
            url: "/user/login",
            method: "POST",
            data: login,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });


        return response.data;
    },

    postForgotPassword: async (email, cancel = false) => {
        const response = await api.request({
            url: "/user/forgotpassword",
            method: "POST",
            data: email,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postResetPassword: async (token, data, cancel = false) => {
        const response = await api.request({
            url: "/user/resetpassword",
            method: "POST",
            data: data,
            params: { resetToken: token },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },


    register : async (data, cancel = false) => {
        const response = await api.request({
            url: "/user/register",
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    }



}

const cancelApiObject = defineCancelApiObject(AuthAPI);
