import React, { useEffect } from "react";

import {
  Box,
  Button,
  Grid,
  GridItem,
  IconButton,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { Select } from "antd";
import Loader from "../Components/Common/Loader";
import { AdminAPI } from "../Apis/adminAPI";
import moment from "moment";
import { companyLogo } from "../Assets";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import { errorMessageHandler } from "../Utils";
import { colors } from "../Styles/Theme/colors";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { AnyMxRecord } from "dns";

const Home = () => {
  const [projectData, setProjectData] = React.useState<any>([]);
  const [projectTicketData, setProjectTicketData] = React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const getData = async () => {
    try {
      setLoading(true);

      const [res1] = await Promise.all([AdminAPI.getProject()]);

      setProjectData(
        res1.data?.map((item: any) => ({ label: item.name, value: item.id }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTicketData = async (id: any) => {
    try {
      setLoading(true);

      const [res2] = await Promise.all([AdminAPI.getProjectTicket(id)]);

      setProjectTicketData(
        res2.data?.map((item: any) => ({
          label: item.summary,
          value: item.id,
          projectId: item.project.id,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [inputData, setInputData] = React.useState<any>({
    project: "",
    ticket: "",
  });

  useEffect(() => {
    if (inputData.project) {
      getTicketData(inputData.project);
    }
  }, [inputData.project]);

  const [punchInState, setPunchInState] = React.useState<any>("start");

  const [punchTime, setPunchTime] = React.useState<any>({
    in: "",
    out: "",
  });

  const clearPunchTime = () => {
    setPunchTime((prev: any) => ({
      ...prev,
      in: "",
      out: "",
    }));
    setPunchInState("start");
    setTimesheetData((prev: any) => ({
      ...prev,
      notes: "",
    }));
  };

  const [timesheetData, setTimesheetData] = React.useState<any>({
    notes: "",
  });

  const [timeElapsed, setTimeElapsed] = React.useState<any>({
    hours: "",
    minutes: "",
    seconds: "",
  });

  // Set clock time
  const getTime = (deadline: any) => {
    const time = Date.now() - Date.parse(deadline);

    let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    let min = Math.floor((time / 1000 / 60) % 60);
    let sec = Math.floor((time / 1000) % 60);

    setTimeElapsed((prev: any) => ({
      ...prev,
      hours: hours,
      minutes: min,
      seconds: sec,
    }));
  };

  async function successLoc(position: any) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    return { lat, lng };
  }

  function errorLoc() {
    error(
      "Unable to retrieve your location. please update your location permission"
    );
    return null;
  }

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = successLoc(position);
            resolve(location);
          },
          () => {
            const error = errorLoc();
            reject(error);
          }
        );
      } else {
        console.log("Geolocation not supported");
        reject("Geolocation not supported");
      }
    });
  };

  const punchIn = async () => {
    try {
      setLoading(true);

      const { lat, lng }: any = await getLocation();

      const obj = {
        projectId: inputData.project,
        projectName: projectData.find(
          (item: any) => item.value === inputData.project
        )?.label,
        ticketId: inputData.ticket,
        ticketName: projectTicketData.find(
          (item: any) => item.value === inputData.ticket
        )?.label,
        date: new Date().getTime(),
        punchInTime: new Date().getTime(),
        lat,
        lng,
      };

      const res = await AdminAPI.postTimesheetPunchIn(obj);

      if (res.success) {
        checkTimesheet();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const punchOut = async () => {
    if (!timesheetData.notes) {
      error("Please enter the notes");
      return;
    }

    if (!timesheetData._id) {
      error("Please punch in first");
      return;
    }

    setLoading(true);

    const { lat, lng }: any = await getLocation();

    try {
      const obj = {
        punchOutTime: new Date().getTime(),
        notes: timesheetData.notes,
        lat,
        lng,
        imageUrl: timesheetData.fileUrl,
      };

      const res = await AdminAPI.postTimesheetPunchOut(timesheetData?._id, obj);

      if (res.success) {
        success("Timesheet updated successfully!");
        setPunchInState("start");

        setPunchTime((prev: any) => ({
          ...prev,
          in: "",
        }));

        clearFields();
      }
    } catch (err) {
      console.log(err);
      const message = errorMessageHandler(err);

      error(message);
    } finally {
      setLoading(false);
    }
  };

  const clearFields = () => {
    localStorage.removeItem("project");
    localStorage.removeItem("ticket");
    setInputData((prev: any) => ({
      ...prev,
      project: "",
      ticket: "",
    }));
  };

  const checkTimesheet = async () => {
    try {
      setLoading(true);

      const obj = {
        projectId: inputData.project,
        ticketId: inputData.ticket,
      };

      const res = await AdminAPI.postCheckTimesheet(obj);

      if (res.success) {
        if (res.data) {
          setPunchInState("end");
          setPunchTime((prev: any) => ({
            ...prev,
            in: moment(res.data.punchInTime),
          }));
          setTimesheetData((prev: any) => ({
            ...prev,
            ...res.data,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (punchTime.in) {
      const interval = setInterval(() => {
        getTime(punchTime.in);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [punchTime.in]);

  useEffect(() => {
    if (inputData.project && inputData.ticket) {
      console.log("cehecking", inputData.project, inputData.ticket);
      localStorage.setItem("project", String(inputData.project));
      localStorage.setItem("ticket", String(inputData.ticket));
      clearPunchTime();
      checkTimesheet();
    }
  }, [inputData.project, inputData.ticket]);

  useEffect(() => {
    const project = localStorage.getItem("project");
    const ticket = localStorage.getItem("ticket");

    if (project && ticket && !inputData.project && !inputData.ticket) {
      console.log(project, ticket);
      setInputData((prev: any) => ({
        ...prev,
        project: Number(project),
        ticket: Number(ticket),
      }));
    }
  }, []);

  const saveNotes = async (notes?: AnyMxRecord) => {
    if (timesheetData) {
      try {
        // setLoading(true);
        const res = await AdminAPI.postTimesheetAddNotes(timesheetData._id, {
          notes: notes ?? timesheetData.notes,
        });

        if (res.success) {
          // success("Notes updated successfully!");
        }
      } catch (err) {
        console.log(err);
        error("Something went wrong!");
      } finally {
        // setLoading(false);
      }
    }
  };

  const uploadFile = async (file: any) => {
    try {
      setLoading(true);
      const reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        setLoading(true);

        // console.log(reader.result);
        const base64String = reader.result;

        const res = await AdminAPI.uploadFile({
          fileType: "timesheet",
          fileBinary: base64String,
          ticketId: inputData.ticket,
        });

        if (res.success) {
          success("File uploaded successfully!");
          setTimesheetData({
            ...timesheetData,
            fileUrl: res.data.url,
          });
          setLoading(false);
        }
      };
    } catch (err) {
      console.log(err);
      error("Something went wrong!");
      setLoading(false);
    }
  };

  return (
    <Box p={"20px"} background={"#f7f7f7"} height={"100vh"} width={"100%"}>
      {loading && <Loader />}

      <Image
        src={companyLogo}
        alt="logo"
        width={"180px"}
        margin={"auto"}
        height={"100px"}
        objectFit={"contain"}
        background={"#000"}
        mb={"30px"}
      />

      <Text fontSize="2xl" textAlign={"center"} fontWeight="semibold" mb="30px">
        Project Timesheet
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={"10px"}>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>
              Project <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              placeholder="Select Project"
              value={Number(inputData.project) || ""}
              onChange={(value) => {
                setInputData((prev: any) => ({
                  ...prev,
                  project: value,
                  ticket: "",
                }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              showSearch
              options={[...projectData]}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>
              Ticket <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              placeholder="Select Ticket"
              value={Number(inputData.ticket) || ""}
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, ticket: value }));
              }}
              showSearch
              style={{
                width: "100%",
                height: "40px",
              }}
              options={[
                ...projectTicketData?.filter(
                  (item: any) => item.projectId == inputData.project
                ),
              ]}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
      </Grid>

      {inputData.project && inputData.ticket ? (
        <Box mt={"40px"} pt={"20px"} borderTop={"2px"} borderColor={"gray.300"}>
          {punchTime.in ? (
            <Box>
              <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                <Text fontWeight={500}>Punch In Time</Text>
                <Text fontSize={"sm"}>{String(punchTime.in)}</Text>
              </Box>

              <Box
                display={"flex"}
                mt={"15px"}
                flexDirection={"column"}
                gap={"5px"}
              >
                <Text fontWeight={500}>Time Elapsed</Text>
                <Text fontSize={"sm"}>
                  {timeElapsed.hours} Hours, {timeElapsed.minutes} Minutes,{" "}
                  {timeElapsed.seconds} seconds{" "}
                </Text>
              </Box>

              <Box
                display={"flex"}
                mt={"15px"}
                flexDirection={"column"}
                gap={"5px"}
              >
                <Text fontWeight={500}>Punch In Location</Text>

                <Text>
                  {timesheetData.punchInLocation?.lat?.toFixed(2)} lat,{" "}
                  {timesheetData.punchInLocation?.lng?.toFixed(2)} lng
                </Text>
              </Box>

              <Box
                display={"flex"}
                mt={"15px"}
                flexDirection={"column"}
                gap={"5px"}
              >
                <Text fontWeight={500}>Notes</Text>
                <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
                  {timesheetData.notes?.map((item: any, index: number) => {
                    return (
                      index !== timesheetData.notes.length - 1 && (
                        <Box
                          px={4}
                          display={"flex"}
                          gap={"10px"}
                          rounded={"15px"}
                          bg={"gray.100"}
                          border={"1px"}
                          borderColor={"gray.400"}
                          alignItems={"center"}
                        >
                          <Box>{item}</Box>
                          <Button
                            variant={"ghost"}
                            p={0}
                            size={"compact"}
                            onClick={() => {
                              const slicedNotes = timesheetData.notes.filter(
                                (item: any, i: number) => {
                                  if (i !== index) {
                                    return item;
                                  }
                                }
                              );

                              setTimesheetData((prev: any) => ({
                                ...prev,
                                notes: [...slicedNotes, item],
                              }));
                            }}
                          >
                            <MdModeEdit size={14} color="gray" />
                          </Button>
                        </Box>
                      )
                    );
                  })}
                </Box>

                <Textarea
                  placeholder="Enter the notes..."
                  background={"#fff"}
                  value={timesheetData.notes[timesheetData.notes.length - 1]}
                  onChange={(e) => {
                    const newNotes = timesheetData.notes.map(
                      (item: any, i: number) => {
                        if (i === timesheetData.notes.length - 1) {
                          return e.target.value;
                        } else {
                          return item;
                        }
                      }
                    );
                    setTimesheetData((prev: any) => ({
                      ...prev,
                      notes: newNotes,
                    }));
                  }}
                />

                {/* {timesheetData.notes?.map((item: any, index: number) => {
                  return (
                    <Box position={"relative"}>
                      <Textarea
                        placeholder="Enter the notes..."
                        background={"#fff"}
                        value={item}
                        onChange={(e) => {
                          setTimesheetData((prev: any) => ({
                            ...prev,
                            notes: prev.notes.map((item: any, i: number) => {
                              if (i === index) {
                                return e.target.value;
                              } else {
                                return item;
                              }
                            }),
                          }));
                        }}
                      />
                      {index > 0 && (
                        <Box
                          position={"absolute"}
                          top={2}
                          right={2}
                          p={1}
                          rounded={"8px"}
                          cursor={"pointer"}
                          bg={"#0000001a"}
                          onClick={async () => {
                            const newNotes = timesheetData.notes.filter(
                              (item: any, i: number) => {
                                if (i !== index) {
                                  return item;
                                }
                              }
                            );
                            setTimesheetData((prev: any) => ({
                              ...prev,
                              notes: newNotes,
                            }));

                            saveNotes(newNotes);
                          }}
                        >
                          <MdDelete color="red" />
                        </Box>
                      )}
                    </Box>
                  );
                })} */}

                <Text
                  cursor={"pointer"}
                  fontSize={"sm"}
                  mt={2}
                  color={colors.primary.main}
                  onClick={() => {
                    if (!timesheetData.notes[timesheetData.notes.length - 1]) {
                      return error("Please enter the notes");
                    }
                    setTimesheetData((prev: any) => ({
                      ...prev,
                      notes: [...prev.notes, ""],
                    }));
                    setTimeout(() => {
                      saveNotes();
                    }, 1000);
                  }}
                >
                  + Add more Notes
                </Text>
              </Box>

              <Box
                display={"flex"}
                mt={"15px"}
                flexDirection={"column"}
                gap={"5px"}
              >
                <Text fontWeight={500}>Upload File</Text>
                {timesheetData?.fileUrl ? (
                  <div className="flex justify-between">
                    <a
                      className="text-[blue] underline"
                      target="_blank"
                      href={timesheetData?.fileUrl}
                    >
                      Image
                    </a>

                    <IconButton
                      aria-label="delete"
                      icon={<MdDelete color="red" />}
                      onClick={() => {
                        setTimesheetData((prev: any) => ({
                          ...prev,
                          fileUrl: "",
                        }));
                      }}
                    />
                  </div>
                ) : (
                  <Input
                    background="white"
                    py={1}
                    accept="image/*"
                    type="file"
                    onChange={(e: any) => {
                      uploadFile(e.target?.files[0]);
                    }}
                  />
                )}
              </Box>
            </Box>
          ) : null}

          {punchInState === "start" ? (
            <Button
              colorScheme="blue"
              onClick={() => {
                punchIn();
              }}
            >
              Punch In
            </Button>
          ) : (
            <Button
              mt={"20px"}
              colorScheme="red"
              onClick={() => {
                punchOut();
              }}
            >
              Punch Out
            </Button>
          )}
        </Box>
      ) : (
        <Box mt={"40px"}>
          <Text color={"gray.600"}>Please Select Project and Ticket</Text>
        </Box>
      )}

      {/* <Table columns={colums} dataSource={leadData} /> */}
    </Box>
  );
};

export default Home;
