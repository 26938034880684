import React, { useEffect } from "react";
import { AdminAPI } from "../../Apis/adminAPI";
import { Box, Text } from "@chakra-ui/react";
import { Table } from "antd";
import { render } from "@testing-library/react";

const Dashboard = () => {
  const [companyUsers, setCompanyUsers] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <a>{record.firstName + " " + record.lastName}</a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
  ];

  useEffect(() => {
    const getCompanyUsers = async () => {
      try {
        setLoading(true);
        const res = await AdminAPI.getCompanyUsers();

        console.log(res);
        if (res.success) {
          setCompanyUsers(res.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getCompanyUsers();

    return () => {};
  }, []);

  return (
    <>
      <Box w={"100%"} display={"flex"}>
        <Text
          fontSize="2xl"
          textAlign={"center"}
          fontWeight="semibold"
          mb="30px"
        >
          User List
        </Text>
      </Box>
      <Box
        overflow={"scroll"}
        mt={"10px"}
        pt={"20px"}
        borderTop={"2px"}
        borderColor={"gray.300"}
      >
        <Table
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "scroll",
            background: "#fff",
            width: "100%",
          }}
          columns={columns}
          dataSource={companyUsers}
          pagination={false}
        />
      </Box>
    </>
  );
};

export default Dashboard;
