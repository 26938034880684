import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { companyLogo } from "../Assets";
import { MdLogout } from "react-icons/md";

const Profile = () => {

    const [user, setUser] = React.useState<any>(null);

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        setUser(user);
    }, []);

  return (
    <Box p={"20px"} position={"relative"} background={"#f7f7f7"} width={"100%"}>
      <Image
        src={companyLogo}
        alt="logo"
        width={"180px"}
        margin={"auto"}
        height={"100px"}
        objectFit={"contain"}
        background={"#000"}
        mb={"30px"}
      />

      <Text fontSize="2xl" textAlign={"center"} fontWeight="semibold" mb="30px">
        Profile
      </Text>

      <Button
        width={"fit-content"}
        background={"#000"}
        color={"#fff"}
        backgroundColor={"#181818"}
        _hover={{ background: "#000" }}
        mb={"10px"}
        position={"absolute"}
        right={"0px"}
        top={"0px"}
        onClick={() => {
            localStorage.clear();
            window.location.href = "/login";
        }}
      >
        <MdLogout size={20} />
      </Button>

    <VStack spacing={"10px"} mt={20} w={"100%"} justifyContent={"flex-start"} alignItems={"flex-start"}>
        <VStack spacing={"5px"} alignItems={"flex-start"}>
            <Text fontSize={"xl"} fontWeight={"semibold"}>
                Full Name
            </Text>
            <Text fontSize={"lg"}>{user?.firstName} {" "} {user?.lastName} </Text>
        </VStack>

        <VStack spacing={"5px"} alignItems={"flex-start"}>
            <Text fontSize={"xl"} fontWeight={"semibold"}>
                Email
            </Text>
            <Text fontSize={"lg"}>{user?.email} </Text>
        </VStack>
    </VStack>

    </Box>
  );
};

export default Profile;
