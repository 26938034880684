import { Button, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { BsLayoutSidebar } from "react-icons/bs";
import { companyLogo } from "../../../Assets";
import { MdLogout } from "react-icons/md";

type Props = {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const Topbar = (props: Props) => {
  const [user, setUser] = React.useState<any>({});

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");
    setUser(user);
  }, []);

  return (
    <div className=" h-[50px] flex justify-between items-center p-2 bg-[#F1F1F1]">
      {/* Disclosure icon */}
      <BsLayoutSidebar
        className="cursor-pointer"
        onClick={() => {
          props.setCollapsed((prev) => !prev);
        }}
      />
      {/* Logo */}
      {/* <Image
        src={companyLogo}
        alt="logo"
        // width={"180px"}
        margin={"auto"}
        height={"30px"}
        objectFit={"contain"}
        background={"#000"}
        mb={"30px"}
      /> */}
      <div className="flex items-center gap-2">
        <div className="h-[36px] w-[36px] rounded-full overflow-hidden bg-primary"></div>
        <p>Admin</p>
        {/* <AiOutlineDown className="cursor-pointer" /> */}
       
      </div>
    </div>
  );
};

export default Topbar;
