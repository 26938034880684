import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Select, Table, Modal } from "antd";
import Loader from "../Components/Common/Loader";
import { AdminAPI } from "../Apis/adminAPI";
import moment from "moment";
import { companyLogo } from "../Assets";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import { getDistanceBetweenTwoPoints } from "../Utils";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";

const Timesheet = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDNMJZwvOAeycMMzJGC7zuzKp_QQqRtRVU",
  });

  const [projectData, setProjectData] = React.useState<any>([]);
  const [projectTicketData, setProjectTicketData] = React.useState<any>([]);

  const [timesheets, setTimesheets] = React.useState<any>([]);

  const [filterData, setFilterData] = React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const getData = async () => {
    try {
      setLoading(true);

      const [res1] = await Promise.all([AdminAPI.getProject()]);

      setProjectData(
        res1.data?.map((item: any) => ({ label: item.name, value: item.id }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTicketData = async (id: any) => {
    try {
      setLoading(true);

      const [res2] = await Promise.all([AdminAPI.getProjectTicket(id)]);

      setProjectTicketData(
        res2.data?.map((item: any) => ({
          label: item.summary,
          value: item.id,
          projectId: item.project.id,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [inputData, setInputData] = React.useState<any>({
    project: "",
    ticket: "",
  });

  useEffect(() => {
    if (inputData.project) {
      getTicketData(inputData.project);
    }
  }, [inputData.project]);

  const COLUMN_STYLE = {
    whiteSpace: "nowrap",
  };

  const columns = [
    {
      title: (
        <Text style={{ whiteSpace: "nowrap", width: "200px" }}>
          Project Name
        </Text>
      ),
      dataIndex: "projectName",
      key: "projectName",
      render: (text: any, data: any) => {
        // console.log(data.userId, users);
        return (
          <Text
            onClick={() => {
              setLocationModalOpen(data);
            }}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Ticket Name</Text>,
      dataIndex: "ticketName",
      key: "ticketName",
      width: "100px",
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Date</Text>,
      dataIndex: "date",
      key: "date",
      render: (text: any) => {
        return (
          <Text
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {moment(text).format("DD MMM YY")}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Status</Text>,
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        return (
          <Text
            style={{
              whiteSpace: "nowrap",
            }}
            color={text === "punched_in" ? "red" : "green"}
          >
            {text === "punched_in" ? "Punched In" : "Punch Out"}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Punch In Time</Text>,
      dataIndex: "punchInTime",
      key: "punchInTime",
      render: (text: any) => {
        return moment(text).format("hh:mm a");
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Punch Out Time</Text>,
      dataIndex: "punchOutTime",
      key: "punchOutTime",
      render: (text: any) => {
        return text ? moment(text).format("hh:mm a") : "-";
      },
    },
    {
      title: (
        <Text style={{ whiteSpace: "nowrap" }}>Location (lat / lng )</Text>
      ),
      dataIndex: "location",
      key: "location",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.punchInLocation && (
              <Text style={{ whiteSpace: "nowrap" }}>
                {record?.punchInLocation?.lat?.toFixed(2)},{" "}
                {record?.punchInLocation?.lng.toFixed(2)} (Punch In)
              </Text>
            )}
            {record?.punchOutLocation && (
              <Text style={{ whiteSpace: "nowrap" }} mt={2}>
                {record?.punchOutLocation?.lat.toFixed(2)},{" "}
                {record?.punchOutLocation?.lng.toFixed(2)} (Punch Out)
              </Text>
            )}
            {record?.distance >= 0 ? (
              <Text style={{ whiteSpace: "nowrap" }} fontSize={"xs"} mt={2}>
                Distance: {record?.distance?.toFixed(2)} km
                {record.distance < 30 ? " ✅" : " ❌"}
              </Text>
            ) : null}
          </>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Total Time (hour)</Text>,
      dataIndex: "totalTime",
      key: "totalTime",
      render: (text: any, data: any) => {
        return (
          moment(data.punchOutTime).diff(data.punchInTime) /
          (1000 * 60 * 60)
        )?.toFixed(2);
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Notes</Text>,
      dataIndex: "notes",
      key: "notes",
      render: (text: any) => {
        return text?.join(", ") ? text?.join(", ") : "-";
      },
    },
  ];

  const getTimesheet = async () => {
    try {
      setLoading(true);

      const res1 = await AdminAPI.getMyTimeSheet();

      if (res1.success) {
        const newData = res1.data?.map((item: any) => {
          const newItem = item;
          if (newItem?.punchInLocation && newItem?.punchOutLocation) {
            newItem["distance"] = getDistanceBetweenTwoPoints(
              {
                lat: newItem?.punchInLocation?.lat,
                lon: newItem?.punchInLocation?.lng,
              },
              {
                lat: newItem?.punchOutLocation?.lat,
                lon: newItem?.punchOutLocation?.lng,
              }
            );
          }
          return {
            ...newItem,
          };
        });

        setTimesheets(newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const filterTimeSheet = async () => {
    let filteredSheets: any[] = timesheets;

    if (inputData.project) {
      filteredSheets = filteredSheets?.filter(
        (item: any) => Number(item?.projectId) === Number(inputData.project)
      );
    }

    if (inputData.ticket) {
      filteredSheets = filteredSheets?.filter(
        (item: any) => Number(item?.ticketId) === Number(inputData.ticket)
      );
    }

    setFilterData(filteredSheets);
  };

  useEffect(() => {
    filterTimeSheet();
  }, [timesheets, inputData.project, inputData.ticket]);

  useEffect(() => {
    getTimesheet();
  }, []);
  const [locationModalOpen, setLocationModalOpen] = useState<any>(undefined);

  return (
    <Box
      // flex={1}
      // overflowY={"auto"}
      width={"100%"}
    >
      {loading && <Loader />}

      <Modal
        title="Location"
        visible={locationModalOpen}
        onOk={() => setLocationModalOpen(undefined)}
        onCancel={() => setLocationModalOpen(undefined)}
      >
        <Box>
          <Box height={500} padding={10} pos={"relative"} overflow={"hidden"}>
            {isLoaded && (
              <GoogleMap
                mapContainerClassName="map-container"
                center={{
                  lat: locationModalOpen?.punchInLocation?.lat,
                  lng: locationModalOpen?.punchInLocation?.lng,
                }}
                zoom={10}
              >
                <MarkerF
                  position={{
                    lat: locationModalOpen?.punchInLocation?.lat || 0,
                    lng: locationModalOpen?.punchInLocation?.lng || 0,
                  }}
                />

                {locationModalOpen?.punchOutLocation?.lat &&
                  locationModalOpen?.punchOutLocation?.lng && (
                    <MarkerF
                      position={{
                        lat: locationModalOpen?.punchOutLocation?.lat || 0,
                        lng: locationModalOpen?.punchOutLocation?.lng || 0,
                      }}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                      }
                    />
                  )}
              </GoogleMap>
            )}
          </Box>
        </Box>
      </Modal>

      <Image
        src={companyLogo}
        alt="logo"
        width={"180px"}
        margin={"auto"}
        height={"100px"}
        objectFit={"contain"}
        background={"#000"}
        mb={"30px"}
      />

      <Text fontSize="2xl" textAlign={"center"} fontWeight="semibold" mb="30px">
        Timesheet Report
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={"10px"}>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>
              Project <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              placeholder="Select Project"
              // defaultValue="lucy"
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, project: value }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              showSearch
              options={[...projectData]}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>
              Ticket <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              placeholder="Select Ticket"
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, ticket: value }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              showSearch
              options={[
                ...projectTicketData?.filter(
                  (item: any) => item.projectId === inputData.project
                ),
              ]}
            />
          </Box>
        </GridItem>
      </Grid>

      <Box
        overflow={"scroll"}
        mt={"40px"}
        pt={"20px"}
        borderTop={"2px"}
        borderColor={"gray.300"}
      >
        <Table
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "scroll",
            background: "#fff",
            width: "100%",
          }}
          columns={columns}
          dataSource={filterData}
          pagination={false}
        />
      </Box>
    </Box>
  );
};

export default Timesheet;
