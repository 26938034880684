import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import Loader from "../Components/Common/Loader";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthAPI } from "../Apis/authAPI";
import { useNavigate } from "react-router-dom";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import { MdHelpOutline, MdLock, MdMail } from "react-icons/md";
import { companyLogo } from "../Assets";
import InfoModal from "../Components/Common/InfoModal";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    if (email === "" || password === "") {
      error("Please enter email and password");
      setLoading(false);
      return;
    }

    if (email === "admin" && password === "admin") {
      localStorage.setItem("auth", "admin");
      // success("Login success");
      navigate("/admin/timesheet");
      return;
    }

    try {
      const res: any = await AuthAPI.postLogin({
        username: email,
        password: password,
      });

      if (res.success) {
        if (res.data.role.includes("admin")) {
          localStorage.setItem("auth", res.token);
          success("Login success");
          navigate("/company/admin");
          return;
        }

        localStorage.setItem("auth", res.token);
        localStorage.setItem("user", JSON.stringify(res.data));
        success("Login success");
        navigate("/user/home");
      }
    } catch (err: any) {
      console.log(err);
      error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitRef = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <InfoModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        type="username"
      />
      {loading ? <Loader /> : null}
      <Box width={"400px"} shadow={"md"} p={10} border={"1px solid #e5e5e5"}>
        <Box>
          <Image
            src={companyLogo}
            alt="logo"
            margin={"auto"}
            height={"50px"}
            objectFit={"contain"}
            background={"#000"}
            mb={"15px"}
          />
          <Text
            fontWeight={"500"}
            color={"primary.main"}
            fontSize={"3xl"}
            textAlign={"center"}
          >
            Login
          </Text>
        </Box>
        <Box mt={"20px"}>
          <FormControl>
            <FormLabel display={"flex"} alignItems={"center"} gap={"5px"}>
              Username
              <MdHelpOutline
                style={{ cursor: "pointer" }}
                onClick={() => {
                 setIsOpen(true);
                }}
              />
            </FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <MdMail />
              </InputLeftElement>
              <Input
                h={"50px"}
                type="text"
                placeholder="Enter username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl mt={"20px"}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <MdLock />
              </InputLeftElement>
              <Input
                h={"50px"}
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement h={"50px"}>
                {showPassword ? (
                  <AiFillEyeInvisible
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                ) : (
                  <AiFillEye
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <Box mt={"10px"} display={"flex"} justifyContent={"flex-end"}>
            <Link href="/forgot" color={"blue.500"} mt={"10px"}>
              Forgot Password?
            </Link>
          </Box>

          <Button
            ref={submitRef}
            onClick={handleSubmit}
            mt={"30"}
            width={"full"}
            h={"45px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"8px"}
            display={"flex"}
            background={"primary.main"}
            color={"white"}
            _hover={{ background: "primary.main" }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;
