import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Modal, Select, Table } from "antd";
import Loader from "../../Components/Common/Loader";
import { AdminAPI } from "../../Apis/adminAPI";
import moment from "moment";
import { companyLogo } from "../../Assets";
import { useToastWrapper } from "../../Wrapper/toastWrapper";

import CsvDownloadButton from "react-json-to-csv";
import { MdFileCopy, MdFileDownload } from "react-icons/md";

// import GoogleMapReact from "google-map-react";

// import { MapContainer } from "react-leaflet/MapContainer";
// import { TileLayer } from "react-leaflet/TileLayer";
// import { useMap } from "react-leaflet/hooks";

// import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
// import { MapContainer } from 'react-leaflet/MapContainer'
// import { TileLayer } from 'react-leaflet/TileLayer'
// import { useMap } from 'react-leaflet/hooks'

const AnyReactComponent = ({ text }: any) => (
  <div
    style={{
      background: "red",
      width: "40px",
      height: "40px",
    }}
  >
    {text}
  </div>
);

const Timesheet = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDNMJZwvOAeycMMzJGC7zuzKp_QQqRtRVU",
  });
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const [projectData, setProjectData] = React.useState<any>([]);
  const [projectTicketData, setProjectTicketData] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);

  const [timesheets, setTimesheets] = React.useState<any>([]);
  const [filteredData, setFilteredData] = React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const getData = async () => {
    try {
      setLoading(true);

      const [res1, res2] = await Promise.all([
        AdminAPI.getProject(),
        AdminAPI.getUsers(),
      ]);

      setProjectData(
        res1.data?.map((item: any) => ({ label: item.name, value: item.id }))
      );

      setUsers(
        res2.data?.map((item: any) => ({
          label: item.firstName + " " + item?.lastName,
          value: item._id,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTicketData = async (id: any) => {
    try {
      setLoading(true);

      const [res2] = await Promise.all([AdminAPI.getProjectTicket(id)]);

      setProjectTicketData(
        res2.data?.map((item: any) => ({
          label: item.summary,
          value: item.id,
          projectId: item.project.id,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [inputData, setInputData] = React.useState<any>({
    project: "",
    ticket: "",
    user: "",
  });

  useEffect(() => {
    if (inputData.project) {
      getTicketData(inputData.project);
    }
  }, [inputData.project]);

  const columns = [
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>User Name</Text>,
      dataIndex: "userId",
      key: "userId",
      render: (text: any, data: any) => {
        // console.log(data.userId, users);
        return (
          <Text
            onClick={() => {
              setLocationModalOpen(data);
            }}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data.user.firstName} {data.user.lastName}
          </Text>
        );
      },
    },
    {
      title: (
        <Text style={{ whiteSpace: "nowrap", width: "200px" }}>
          Project Name
        </Text>
      ),
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Ticket Name</Text>,
      dataIndex: "ticketName",
      key: "ticketName",
      width: "100px",
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Date</Text>,
      dataIndex: "date",
      key: "date",
      render: (text: any) => {
        return (
          <Text
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {moment(text).format("DD MM YY")}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Status</Text>,
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        return (
          <Text
            style={{
              whiteSpace: "nowrap",
            }}
            color={text === "punched_in" ? "red" : "green"}
          >
            {text === "punched_in" ? "Punched In" : "Punch Out"}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Punch In Time</Text>,
      dataIndex: "punchInTime",
      key: "punchInTime",
      render: (text: any) => {
        return moment(text).format("hh:mm a");
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Punch Out Time</Text>,
      dataIndex: "punchOutTime",
      key: "punchOutTime",
      render: (text: any) => {
        return moment(text).format("hh:mm a");
      },
    },
    {
      title: (
        <Text style={{ whiteSpace: "nowrap" }}>Location (lat / lng )</Text>
      ),
      dataIndex: "location",
      key: "location",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.punchInLocation && (
              <Text style={{ whiteSpace: "nowrap" }}>
                {record?.punchInLocation?.lat?.toFixed(2)},{" "}
                {record?.punchInLocation?.lng.toFixed(2)} (Punch In)
              </Text>
            )}
            {record?.punchOutLocation && (
              <Text style={{ whiteSpace: "nowrap" }} mt={2}>
                {record?.punchOutLocation?.lat.toFixed(2)},{" "}
                {record?.punchOutLocation?.lng.toFixed(2)} (Punch Out)
              </Text>
            )}
            {record?.distance >= 0 ? (
              <Text style={{ whiteSpace: "nowrap" }} fontSize={"xs"} mt={2}>
                Distance: {record?.distance?.toFixed(2)} km
                {record.distance < 2 ? " ✅" : "❌"}
              </Text>
            ) : null}
          </>
        );
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Total Time (hour)</Text>,
      dataIndex: "totalTime",
      key: "totalTime",
      render: (text: any, data: any) => {
        return (
          moment(data.punchOutTime).diff(data.punchInTime) /
          (1000 * 60 * 60)
        )?.toFixed(2);
      },
    },
    {
      title: <Text style={{ whiteSpace: "nowrap" }}>Notes</Text>,
      dataIndex: "notes",
      key: "notes",
      render: (text: any) => (text.join(", ") ? text.join(", ") : "-"),
    },
  ];

  const getTimesheet = async () => {
    try {
      setLoading(true);

      const res1 = await AdminAPI.getTimeSheets();

      if (res1.success) {
        setTimesheets(res1.data);
        setFilteredData(res1.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    let filteredNew: any[] = timesheets;

    if (inputData.user) {
      filteredNew = filteredNew.filter(
        (item: any) => item.user._id === inputData.user
      );
    }

    if (inputData.ticket) {
      filteredNew = filteredNew.filter(
        (item: any) => Number(item.ticketId) === Number(inputData.ticket)
      );
    }

    if (inputData.project) {
      filteredNew = filteredNew.filter(
        (item: any) => Number(item.projectId) === Number(inputData.project)
      );
    }

    setFilteredData(filteredNew);
  };

  useEffect(() => {
    filterData();
  }, [inputData.project, inputData.ticket, inputData.user]);

  useEffect(() => {
    getTimesheet();
  }, []);

  const [locationModalOpen, setLocationModalOpen] = useState<any>(undefined);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  // const position = [51.505, -0.09];

  const position = [51.505, -0.09];

  return (
    <Box
      p={"20px"}
      maxH={"100%"}
      overflowY={"scroll"}
      background={"#f7f7f7"}
      boxSizing="border-box"
    >
      {loading && <Loader />}

      <Modal
        title="Location"
        visible={locationModalOpen}
        onOk={() => setLocationModalOpen(undefined)}
        onCancel={() => setLocationModalOpen(undefined)}
      >
        <Box>
          <Box height={500} padding={10} pos={"relative"} overflow={"hidden"}>
            {isLoaded && (
              <GoogleMap
                mapContainerClassName="map-container"
                center={{
                  lat: locationModalOpen?.punchInLocation?.lat,
                  lng: locationModalOpen?.punchInLocation?.lng,
                }}
                zoom={10}
              >
                <MarkerF
                  position={{
                    lat: locationModalOpen?.punchInLocation?.lat || 0,
                    lng: locationModalOpen?.punchInLocation?.lng || 0,
                  }}
                />

                {locationModalOpen?.punchOutLocation?.lat &&
                  locationModalOpen?.punchOutLocation?.lng && (
                    <MarkerF
                      position={{
                        lat: locationModalOpen?.punchOutLocation?.lat || 0,
                        lng: locationModalOpen?.punchOutLocation?.lng || 0,
                      }}
                      icon={
                        "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                      }
                    />
                  )}
              </GoogleMap>
            )}
          </Box>
        </Box>
      </Modal>

      <Box w={"100%"} display={"flex"}>
        <Text
          fontSize="2xl"
          textAlign={"center"}
          fontWeight="semibold"
          mb="30px"
        >
          Timesheet Report
        </Text>
        <Button
          bg={"#000"}
          color={"white"}
          ml={"auto"}
          _hover={{
            background: "#000",
          }}
        >
          <CsvDownloadButton
            filename={"timeentries_" + moment().format("DD_MM_YYYY") + ".csv"}
            delimiter=","
            data={filteredData?.map((item: any) => {
              return {
                name: item.user.firstName + " " + item.user.lastName,
                email: item.user.email,
                project: item.projectName,
                ticket: item.ticketName,
                date: moment(item.date).format("DD MM YYYY"),
                status:
                  item.status === "punched_in" ? "Punched In" : "Punch Out",
                punchInTime: moment(item.punchInTime).format("hh:mm a"),
                punchOutTime: moment(item.punchOutTime).format("hh:mm a"),
                distance: item?.distance?.toFixed(2),
                totalTime: (
                  moment(item.punchOutTime).diff(item.punchInTime) /
                  (1000 * 60 * 60)
                )?.toFixed(2),
                notes: item.notes,
                punchInLocation:
                  item?.punchInLocation?.lat?.toFixed(2) +
                  ", " +
                  item?.punchInLocation?.lng?.toFixed(2),
                punchOutLocation:
                  item?.punchOutLocation?.lat?.toFixed(2) +
                  ", " +
                  item?.punchOutLocation?.lng?.toFixed(2),
              };
            })}
          >
            <Box display={"flex"} gap={2}>
              <MdFileDownload size={20} />
              Export
            </Box>
          </CsvDownloadButton>
        </Button>
      </Box>

      <Grid templateColumns="repeat(6, 1fr)" gap={"10px"}>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>Project</Text>
            <Select
              placeholder="Select Project"
              // defaultValue="lucy"
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, project: value }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              options={[...projectData]}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>Ticket</Text>
            <Select
              placeholder="Select Ticket"
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, ticket: value }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              options={[
                ...projectTicketData?.filter(
                  (item: any) => item.projectId === inputData.project
                ),
              ]}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>Users</Text>
            <Select
              placeholder="Select User"
              value={inputData.user || null}
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, user: value }));
              }}
              
              style={{
                width: "100%",
                height: "40px",
              }}
              options={[...users]}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
      </Grid>

      <Box
        overflow={"scroll"}
        mt={"40px"}
        pt={"20px"}
        borderTop={"2px"}
        borderColor={"gray.300"}
      >
        <Table
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "scroll",
            background: "#fff",
            width: "100%",
          }}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
        />
      </Box>
    </Box>
  );
};

export default Timesheet;
