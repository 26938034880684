import React, { useEffect } from "react";
import { AdminAPI } from "../../Apis/adminAPI";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

import { Table, Select } from "antd";
import { render } from "@testing-library/react";
import { CommonApi } from "../../Apis/commonApi";
import Loader from "../../Components/Common/Loader";

const Tickets = () => {
  const [projects, setProjects] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      render: (text: any, record: any) => <Text>{record.company.name}</Text>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text: any, record: any) => <Text>{record.department.name}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => <Text>{record.status.name}</Text>,
    },

    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      render: (text: any, record: any) => <Text>{record.site.name}</Text>,
    },
  ];

  const [projectData, setProjectData] = React.useState([]);
  const [projectTicketData, setProjectTicketData] = React.useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const [res1] = await Promise.all([AdminAPI.getProject()]);

      setProjectData(
        res1.data?.map((item: any) => ({ label: item.name, value: item.id }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTicketData = async (id: any) => {
    try {
      setLoading(true);

      const [res2] = await Promise.all([AdminAPI.getProjectTicket(id)]);

      setProjectTicketData(res2.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [inputData, setInputData] = React.useState({
    project: "",
  });

  useEffect(() => {
    if (inputData.project) {
      getTicketData(inputData.project);
    }
  }, [inputData]);

  return (
    <>
      {loading && <Loader />}
      <Box w={"100%"} display={"flex"}>
        <Text
          fontSize="2xl"
          textAlign={"center"}
          fontWeight="semibold"
          mb="30px"
        >
          Ticket List
        </Text>
      </Box>
      <Grid templateColumns="repeat(6, 1fr)" gap={"10px"}>
        <GridItem colSpan={2}>
          <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
            <Text fontWeight={500}>Project</Text>
            <Select
              placeholder="Select Project"
              // defaultValue="lucy"
              onChange={(value) => {
                setInputData((prev: any) => ({ ...prev, project: value }));
              }}
              style={{
                width: "100%",
                height: "40px",
              }}
              options={[...projectData]}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Box>
        </GridItem>
      </Grid>
      <Box
        overflow={"scroll"}
        mt={"10px"}
        pt={"20px"}
        borderTop={"2px"}
        borderColor={"gray.300"}
      >
        <Table
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "scroll",
            background: "#fff",
            width: "100%",
          }}
          columns={columns}
          dataSource={projectTicketData}
          pagination={false}
        />
      </Box>
    </>
  );
};

export default Tickets;
