import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import Loader from "../Components/Common/Loader";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthAPI } from "../Apis/authAPI";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import { MdLock, MdMail, MdPassword } from "react-icons/md";
import { companyLogo } from "../Assets";

const ChangePassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [token, setToken] = React.useState("");

  const [searchParams] = useSearchParams();

  const { success, error } = useToastWrapper();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!token) {
      error("Invalid token");
      return;
    }

    if (!password || !confirmPassword) {
      error("Please enter all the fields");
      return;
    }

    if (password !== confirmPassword) {
      error("Password and confirm password should be same");
      return;
    }

    setLoading(true);

    try {
      const res: any = await AuthAPI.postResetPassword(token, {
        password: password,
      });

      if (res.success) {
        success("Password changed successfully");
        navigate("/login");
      }
    } catch (err: any) {
      console.log(err);
      error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitRef = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (searchParams && searchParams.get("token")) {
      setToken(searchParams.get("token") || "");
    }
  }, [searchParams]);

  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      {loading ? <Loader /> : null}
      <Box width={"400px"} shadow={"md"} p={10} border={"1px solid #e5e5e5"}>
        <Box>
          <Image
            src={companyLogo}
            alt="logo"
            margin={"auto"}
            height={"50px"}
            objectFit={"contain"}
            background={"#000"}
            mb={"15px"}
          />
          <Text
            fontWeight={"500"}
            color={"primary.main"}
            fontSize={"3xl"}
            textAlign={"center"}
          >
            Change Password
          </Text>
        </Box>
        <Box mt={"20px"}>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <MdLock />
              </InputLeftElement>
              <Input
                h={"50px"}
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={"20px"}>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <MdLock />
              </InputLeftElement>
              <Input
                h={"50px"}
                type="password"
                placeholder="Enter confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          <Button
            ref={submitRef}
            onClick={handleSubmit}
            mt={"30"}
            width={"full"}
            h={"45px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"8px"}
            display={"flex"}
            background={"primary.main"}
            color={"white"}
            _hover={{ background: "primary.main" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default ChangePassword;
