export const colors = {
  primary: {
    main: "#050A30",
  },
  secondary: {
    main: "#F5793D",
  },
  acc_dark: {
    main: "#0E122F",
  },
  alternate: {
    main: "#FF5300",
  },
  heading: {
    main: "#333333",
  },
  button: {
    yellow: "#FEBA08",
    green: "#4CAF50",
    red: "#FF3D00",
  },
  text: {
    black: "#121927",
  },
  linear: {
    100: "linear-gradient(180deg, #050A30 0%, rgba(5, 10, 48, 0.00) 444.05%)",
    200: "linear-gradient(180deg, #FFEBDC 0%, rgba(255, 235, 220, 0.4) 100%)",
  },
  radial: {
    100: "radial-gradient(220.05% 132.6% at -47.33% -13.5%, #FFDB87 0.27%, #FFDB85 43.46%, #FFB6B0 65.44%, #FFB3B4 100%)",
  },
};
