import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { FaHome } from "react-icons/fa";
import { MdAccessTimeFilled, MdPerson } from "react-icons/md";



const MobileLayout = () => {

    const tabList = [
        {
            id: "home",
            label: "Home",
            icon: FaHome,
            path: "/user/home",
        },
        {
            id: "entries",
            label: "Entries",
            icon: MdAccessTimeFilled,
            path: "/user/timesheet",
        },
        {
            id: "Profile",
            label: "Profile",
            icon: MdPerson,
            path: "/user/profile",
        },
    ];


    const [selectedTab, setSelectedTab] = React.useState("home");

    const navigate = useNavigate();

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        if (!auth) {
            navigate("/login")
        }
    }, []);

    return (
        <Flex
            background={"#f7f7f7"}
            maxW={"600px"}
            w="100%"
            position={"relative"}
            flexDirection="column"
            alignSelf={"center"}
            mx={"auto"}
            display={"flex"}
            minH={"100vh"}
            maxH={"100vh"}
            overflow={"auto"}
        >
            <Box flex={1} pb={0} overflowY={"auto"} maxHeight={"100%"} display={"flex"} height={"100%"} flexDir={"column"}>
                <Box p={"20px"}>
                    <Outlet />
                </Box>
                <Box p={"10px"} w={"100%"}></Box>

            </Box>

            <Box background={"#fff"} shadowTop={"md"} borderTopRadius={"30px"} border={"1px"} borderColor={"gray.300"} overflow={"hidden"} p={5} w={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} maxH={"80px"}
                minH={"80px"} >

                {
                    tabList.map((tab, index) => {
                        return (
                            <VStack
                                onClick={() => {
                                    setSelectedTab(tab.id)
                                    navigate(tab.path)
                                }}
                                cursor={"pointer"}
                                key={index}
                                w={"100px"}


                            >
                                <Box>
                                    <tab.icon size={18} color={selectedTab === tab.id ? "#ff6900" : "#CBD5E0"} />
                                </Box>
                                <Text fontSize={"sm"}>
                                    {tab.label}
                                </Text>
                            </VStack>
                        )
                    })
                }

            </Box>
        </Flex>
    );
};

export default MobileLayout;
