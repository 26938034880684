import React from "react";

import { Modal } from "antd";
import { Box, Link, Text } from "@chakra-ui/react";
import { colors } from "../../Styles/Theme/colors";

import { List, ListItem, ListIcon } from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";


interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: "username" | "clientId" | "authKey";
}

const infoModalData = [
  {
    id: "username",
    title: "Username",
    content: <Text>Username is your connectwise email</Text>,
  },
  {
    id: "clientId",
    title: "Client ID",
    content: (
      <Text>
        Client ID is the unique identifier for your account. You can find this
        in by logging in to{" "}
        <Link
          color={"blue"}
          textDecor={"underline"}
          href="https://developer.connectwise.com/ClientID"
        >
          https://developer.connectwise.com/ClientID
        </Link>{" "}
      </Text>
    ),
  },
  {
    id: "authKey",
    title: "Auth Key",
    content: (
        <Box>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            Go to <strong>System</strong> {">"} <strong>Members</strong>{">"} <strong>Your Account</strong> {">"} <strong>API Keys</strong> {">"} <strong>Create a Key</strong>.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            After creating the key, note down the <strong>public key</strong> and <strong>private key</strong>.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            Create a Basic Auth token using the public key and private key.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            Use the following format to create the token:
            <List spacing={1} ml={5} mt={2}>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                <strong>Username:</strong> Sira_cs1+public_key
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                <strong>Password:</strong> private key
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            For Postman:
            <List spacing={1} ml={5} mt={2}>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                Use Basic Auth in Postman.
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                Add username and password in the above format.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <ListIcon as={BsCircleFill} color="green.500" />
            If you don't know how to create a Basic Auth token, you can copy it from Postman.
            <List spacing={1} ml={5} mt={2}>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                Make a request using point number 5.
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                Open console and check request headers. 
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="blue.500" />
                You can get it as authorization. 
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Box>
    ),
  },
];

const InfoModal = ({ isOpen, onClose, type }: InfoModalProps) => {
  const { title = "", content = "" } =
    infoModalData.find((data) => data.id === type) ?? {};

  if (!title || !content) {
    return <></>;
  }

  return (
    <Modal
      title={"Info: " + title}
      visible={isOpen}
      onOk={() => onClose()}
      okText="Okay"
      okButtonProps={{
        style: { background: colors.primary.main, fontWeight: 500 },
      }}
      onCancel={() => onClose()}
      cancelButtonProps={{ disabled: true, style: { display: "none" } }}
    >
      <Box>{content}</Box>
    </Modal>
  );
};

export default InfoModal;
