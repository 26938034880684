import { Flex, Button, Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";

const Sop = () => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const goToPrevPage = () => setPageNumber(pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1);

  return (
    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
      <Box marginX={"auto"} overflowY={"scroll"} maxH={"80vh"}>
        <Document file="./sop.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </Box>

      <Flex justifyContent="center" mt={6}>
        <Button onClick={goToPrevPage} disabled={pageNumber <= 1} mr={2}>
          Previous
        </Button>
        <Button onClick={goToNextPage} disabled={pageNumber >= (numPages ?? 1)}>
          Next
        </Button>
      </Flex>
      <Flex justifyContent="center" mt={2}>
        Page {pageNumber} of {numPages}
      </Flex>
    </Box>
  );
};

export default Sop;
