// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select-noborder .ant-select-selector {
  border: 0px !important;
}

.custom-select-noborder .ant-select-selection-placeholder {
  font-size: 16px;
  color: gray;
}
.custom-select-noborder input[type="search"]::placeholder {
  color: red !important;
}


.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);

}

.ant-spin .ant-spin-dot-item {
  background-color: #ff6900;
}


.App {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,qBAAqB;AACvB;;;AAGA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,MAAM;EACN,OAAO;EACP,+BAA+B;;AAEjC;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".custom-select-noborder .ant-select-selector {\n  border: 0px !important;\n}\n\n.custom-select-noborder .ant-select-selection-placeholder {\n  font-size: 16px;\n  color: gray;\n}\n.custom-select-noborder input[type=\"search\"]::placeholder {\n  color: red !important;\n}\n\n\n.loader_container {\n  position: fixed;\n  z-index: 9999;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.25);\n\n}\n\n.ant-spin .ant-spin-dot-item {\n  background-color: #ff6900;\n}\n\n\n.App {\n  height: 100vh;\n  width: 100vw;\n}\n\n.map-container {\n  height: 100%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
